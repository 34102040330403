import { useState } from "react";
import { Dialog, DialogBackdrop, DialogPanel } from "@headlessui/react";
import PrimaryButton from "./PrimaryButton";

export default function ContactUs({ isOpen, handlecloseContactUsModal }) {
  // 1. Updated formData state with all required fields and default values
  const [formData, setFormData] = useState({
    FullName: "",
    Email: "",
    PhoneNumber: "",
    Company: "",
    Designation: "",
    Country: "",
    Message: "",
  });
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});

  // Handle input changes
  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
    setErrors({
      ...errors,
      [e.target.name]: "",
    });
  };

  // 2. Enhanced form validation to include new fields
  const validateForm = () => {
    let formIsValid = true;
    let newErrors = {};

    // Validate FullName
    if (!formData.FullName.trim() || formData.FullName.trim().split(" ").length < 2) {
      formIsValid = false;
      newErrors.FullName = "Please enter at least two names (e.g., First and Last name).";
    }

    // Validate Email
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!formData.Email.trim()) {
      formIsValid = false;
      newErrors.Email = "Email is required.";
    } else if (!emailRegex.test(formData.Email)) {
      formIsValid = false;
      newErrors.Email = "Please enter a valid email address.";
    }

    // Validate PhoneNumber
    if (!/^\d{10}$/.test(formData.PhoneNumber)) {
      formIsValid = false;
      newErrors.PhoneNumber = "Please enter a valid 10-digit phone number.";
    }

    // Validate Company
    if (!formData.Company.trim()) {
      formIsValid = false;
      newErrors.Company = "Company name is required.";
    }

    // Validate Designation
    if (!formData.Designation.trim()) {
      formIsValid = false;
      newErrors.Designation = "Designation is required.";
    }

    // Validate Country
    if (!formData.Country.trim()) {
      formIsValid = false;
      newErrors.Country = "Please select your country.";
    }

    // Validate Message (if required)
    // Uncomment the following lines if Message should be mandatory
    /*
    if (!formData.Message.trim()) {
      formIsValid = false;
      newErrors.Message = "Please enter your message.";
    }
    */

    setErrors(newErrors);
    return formIsValid;
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;

    setLoading(true);

    const url = "https://actionabl.featsystems.com/api/Lead/SendEmail";
    const payload = {
      FullName: formData.FullName,
      Company: formData.Company,
      Email: formData.Email,
      PhoneNumber: formData.PhoneNumber,
      Designation: formData.Designation,
      Country: formData.Country,
      Message: formData.Message,
    };
   

    try {
      console.log('payload',formData);
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });

      if (!response.ok) {
        throw new Error(`Error: ${response.status}`);
      }

      alert("Thank you!\nWe've received your message and will respond within 24 hours.");
      handlecloseContactUsModal();

      // Reset form fields after successful submission
      setFormData({
        FullName: "",
        Email: "",
        PhoneNumber: "",
        Company: "",
        Designation: "",
        Country: "",
        Message: "",
      });
    } catch (error) {
      console.error("There was an error submitting the form:", error);
      alert("Failed to submit the form.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog
      open={isOpen}
      as="div"
      className="relative z-50 focus:outline-none"
      onClose={handlecloseContactUsModal}
    >
      <DialogBackdrop className="fixed inset-0 bg-black/30 backdrop-blur-xl" />
      <div className="fixed inset-0 z-50 w-screen overflow-y-auto">
        <div className="flex min-h-full items-center justify-center p-3 sm:p-4">
          <DialogPanel className="w-full max-w-[1280px] rounded-[32px] bg-white p-5 sm:p-8 xl:p-12 duration-300 ease-out">
            <h4 className="text-center text-2xl sm:text-3xl lg:text-4xl font-bold leading-[120%] text-black mb-6">
              Contact Us
            </h4>

            <form onSubmit={handleSubmit}>
              {/* Full Name and Email */}
              <div className="flex flex-col sm:flex-row items-center justify-between sm:space-x-4 xl:space-x-6 mb-4">
                <div className="w-full mb-2">
                  <label htmlFor="FullName" className="text-lg font-normal text-[#1D2939]">
                    Full Name <span className="text-red-600">*</span>
                  </label>
                  <input
                    required
                    name="FullName"
                    type="text"
                    placeholder="Enter your full name"
                    value={formData.FullName}
                    onChange={handleChange}
                    className="border border-[#1018281F] rounded-2xl shadow bg-white placeholder:text-[#667085] text-lg h-14 w-full px-4 py-2 mt-2"
                  />
                  {errors.FullName && <p className="text-red-600 mt-1 text-sm">{errors.FullName}</p>}
                </div>
                <div className="w-full">
                  <label htmlFor="Email" className="text-lg font-normal text-[#1D2939]">
                    Email <span className="text-red-600">*</span>
                  </label>
                  <input
                    required
                    name="Email"
                    type="email"
                    placeholder="Enter your email"
                    value={formData.Email}
                    onChange={handleChange}
                    className="border border-[#1018281F] rounded-2xl shadow bg-white placeholder:text-[#667085] text-lg h-14 w-full px-4 py-2 mt-2"
                  />
                  {errors.Email && <p className="text-red-600 mt-1 text-sm">{errors.Email}</p>}
                </div>
              </div>

              {/* Phone Number and Company */}
              <div className="flex flex-col sm:flex-row items-center justify-between sm:space-x-4 xl:space-x-6 mb-4">
                <div className="w-full mb-2">
                  <label htmlFor="PhoneNumber" className="text-lg font-normal text-[#1D2939]">
                    Phone Number <span className="text-red-600">*</span>
                  </label>
                  <input
                    required
                    name="PhoneNumber"
                    type="tel"
                    placeholder="Enter your phone number"
                    value={formData.PhoneNumber}
                    onChange={handleChange}
                    pattern="\d{10}"
                    minLength="10"
                    maxLength="10"
                    className="border border-[#1018281F] rounded-2xl shadow bg-white placeholder:text-[#667085] text-lg h-14 w-full px-4 py-2 mt-2"
                  />
                  {errors.PhoneNumber && <p className="text-red-600 mt-1 text-sm">{errors.PhoneNumber}</p>}
                </div>
                <div className="w-full">
                  <label htmlFor="Company" className="text-lg font-normal text-[#1D2939]">
                    Company <span className="text-red-600">*</span>
                  </label>
                  <input
                    required
                    name="Company"
                    type="text"
                    placeholder="Enter your company"
                    value={formData.Company}
                    onChange={handleChange}
                    className="border border-[#1018281F] rounded-2xl shadow bg-white placeholder:text-[#667085] text-lg h-14 w-full px-4 py-2 mt-2"
                  />
                  {errors.Company && <p className="text-red-600 mt-1 text-sm">{errors.Company}</p>}
                </div>
              </div>

              {/* Designation and Country */}
              <div className="flex flex-col sm:flex-row items-center justify-between sm:space-x-4 xl:space-x-6 mb-4">
                <div className="w-full mb-2">
                  <label htmlFor="Designation" className="text-lg font-normal text-[#1D2939]">
                    Designation <span className="text-red-600">*</span>
                  </label>
                  <input
                    required
                    name="Designation"
                    type="text"
                    placeholder="Enter your designation"
                    value={formData.Designation}
                    onChange={handleChange}
                    className="border border-[#1018281F] rounded-2xl shadow bg-white placeholder:text-[#667085] text-lg h-14 w-full px-4 py-2 mt-2"
                  />
                  {errors.Designation && <p className="text-red-600 mt-1 text-sm">{errors.Designation}</p>}
                </div>
                <div className="w-full">
                  <label htmlFor="Country" className="text-lg font-normal text-[#1D2939]">
                    Country <span className="text-red-600">*</span>
                  </label>
                  <select
  required
  name="Country"
  value={formData.Country}
  onChange={handleChange}
  className="border border-[#1018281F] rounded-2xl shadow bg-white text-lg h-14 w-full px-4 py-2 mt-2"
>
  <option value="" disabled>Select your country</option>
  <option value="United States">United States</option>
  <option value="China">China</option>
  <option value="India">India</option>
  <option value="Germany">Germany</option>
  <option value="United Kingdom">United Kingdom</option>
  <option value="France">France</option>
  <option value="Brazil">Brazil</option>
  <option value="Russia">Russia</option>
  <option value="Canada">Canada</option>
  <option value="Japan">Japan</option>
  <option value="Australia">Australia</option>
  <option value="Mexico">Mexico</option>
  <option value="South Korea">South Korea</option>
  <option value="Italy">Italy</option>
  <option value="Spain">Spain</option>
  <option value="Turkey">Turkey</option>
  <option value="Saudi Arabia">Saudi Arabia</option>
  <option value="Argentina">Argentina</option>
  <option value="South Africa">South Africa</option>
  <option value="Egypt">Egypt</option>
  <option value="Sweden">Sweden</option>
  <option value="Netherlands">Netherlands</option>
  <option value="Switzerland">Switzerland</option>
  <option value="Norway">Norway</option>
  <option value="Poland">Poland</option>
  <option value="Austria">Austria</option>
  <option value="Belgium">Belgium</option>
  <option value="Denmark">Denmark</option>
  <option value="Finland">Finland</option>
  <option value="New Zealand">New Zealand</option>
  <option value="Thailand">Thailand</option>
  <option value="Malaysia">Malaysia</option>
  <option value="Singapore">Singapore</option>
  <option value="Pakistan">Pakistan</option>
  <option value="Bangladesh">Bangladesh</option>
  <option value="Vietnam">Vietnam</option>
  <option value="Philippines">Philippines</option>
  <option value="Indonesia">Indonesia</option>
  <option value="Nigeria">Nigeria</option>
  <option value="Kenya">Kenya</option>
  <option value="Ghana">Ghana</option>
  <option value="Colombia">Colombia</option>
  <option value="Peru">Peru</option>
  <option value="Chile">Chile</option>
  <option value="Venezuela">Venezuela</option>
  <option value="Cuba">Cuba</option>
  <option value="Morocco">Morocco</option>
  <option value="Algeria">Algeria</option>
  <option value="Israel">Israel</option>
</select>

                  {errors.Country && <p className="text-red-600 mt-1 text-sm">{errors.Country}</p>}
                </div>
              </div>

              {/* Message */}
              <div className="w-full mb-4">
                <label htmlFor="Message" className="text-lg font-normal text-[#1D2939]">
                  Message <span className="text-gray-500">(optional)</span>
                </label>
                <textarea
                  name="Message"
                  value={formData.Message}
                  onChange={handleChange}
                  className="border border-[#1018281F] rounded-2xl shadow bg-white placeholder:text-[#667085] text-lg h-28 w-full px-4 py-2 mt-2 resize-none"
                  placeholder="Write your message....."
                ></textarea>
                {errors.Message && <p className="text-red-600 mt-1 text-sm">{errors.Message}</p>}
              </div>

              {/* Submit Button */}
              <div className="flex justify-center">
                <PrimaryButton
                  type="submit"
                  disabled={loading}
                  className="max-w-[262px] w-full mx-auto h-14 sm:h-16"
                >
                  {loading ? "Sending..." : "Submit"}
                </PrimaryButton>
              </div>
            </form>
          </DialogPanel>
        </div>
      </div>
    </Dialog>
  );
}
