const AllRightReserved = () => {
  return (
    <section className="border-t border-dark h-[61px] w-full flex items-center justify-center px-4 bg-reserved">
      <div className="flex items-center">
        <p className="mr-5 text-sm leading-[100%] text-light-gray">
          Actionabl © {new Date().getFullYear()}
        </p>
        <span className="flex border-l border-deep-night w-[2px] h-[22px]"></span>
        <p className="text-sm leading-[100%] text-light-gray pl-5 ">
          All rights reserved
        </p>
      </div>
    </section>
  );
};

export default AllRightReserved;
