import React, { useState } from "react";
import { Link } from "react-router-dom";
import { LoadingIcon, LogoIcon } from "./Icons";
import DiscoverTheFuture from "../homepage/DiscoverTheFuture";
import { footerItems } from "./Helper";

//  social links
const SocialLinks = ({ links }) => (
  <div className="flex space-x-4">
    {links.map((link) => (
      <Link
        target="_blank"
        key={link.name}
        to={link.href}
        className="md:w-12 w-10 md:h-12 h-10 flex items-center justify-center bg-candy hover:shadow-md hover:shadow-candy rounded-full duration-200 ease-in-out transition-all"
      >
        {link.icon}
      </Link>
    ))}
  </div>
);

// Company or links section
const FooterLinksSection = ({ title, items }) => (
  <div className="xl:max-w-[218px] md:max-w-[48%] w-full">
    {/* <h3 className="lg:text-2xl text-xl text-white font-bold leading-[150%] mb-4">
      {title}
    </h3> */}
    {/* <ul className="space-y-[7px]">
      {items.map((item) => (
        <li key={item.name}>
          <Link to={item.href} className="footer-li-text group pb-2">
            {item.name}
            <span className="footer-li-hover"></span>
          </Link>
        </li>
      ))}
    </ul> */}
  </div>
);

const Footer = () => {
  const [email, setEmail] = useState("");
  const [buttonState, setButtonState] = useState("initial");

  const handleSubmit = (event) => {
    event.preventDefault();
    if (buttonState === "initial") {
      setButtonState("loading");
      // console.log("Email subscribed:", email);

      setTimeout(() => {
        setButtonState("subscribed");
        setEmail("");
      }, 2000);
    }
  };
  return (
    <footer className="footer-bg relative w-full">
      <DiscoverTheFuture />
      <div className="main-container 3xl:pb-[161px] 1xl:pb-[140px] lg:pb-[100px] md:pb-20 sm:pb-14 pb-10">
        <div className="flex justify-between 1xl:gap-28 xl:gap-20 gap-14">
          {/* Left Section with Logo and Social Links */}
          <div className="max-w-[306px] w-full xl:block hidden">
            <Link to={"/"} className="w-full max-w-[214px] flex">
              <LogoIcon />
            </Link>
            <p className="text-lg leading-[110%] tracking-[-0.18px] text-white my-5">
              Realize the Vision of Autonomous Enterprise
            </p>
            <SocialLinks links={footerItems.socialLinks} />
          </div>

          <div className="flex xl:flex-nowrap flex-wrap justify-between w-full xl:gap-[71px] gap-10">
            {/* Footer Links Sections */}
            <div className="flex justify-between w-full">
              <FooterLinksSection title="Company" items={footerItems.company} />
              <FooterLinksSection title="Links" items={footerItems.links} />
            </div>

            {/* Mobile logo and links */}
            <div className="w-fit hidden md:block xl:hidden">
              <Link to={"/"} className="w-full max-w-[214px] flex">
                <LogoIcon />
              </Link>
              <p className="text-lg leading-[110%] tracking-[-0.18px] text-white my-5">
                Realize the Vision of Autonomous Enterprise
              </p>
              <SocialLinks links={footerItems.socialLinks} />
            </div>

            {/* Newsletter subscription */}
            {/* <div className="xl:max-w-[371px] md:max-w-[48%] w-full">
              <h3 className="lg:text-2xl text-[22px] text-white font-bold leading-[150%] ">
                Subscribe to Newsletter
              </h3>
              <p className="text-base sm:text-lg leading-[120%] sm:leading-[110%] tracking-[-0.18px] text-white mb-5 mt-3">
                Join our subscribers list to get the latest news and updates.
              </p>
              <form
                onSubmit={handleSubmit}
                className="flex flex-col xs:flex-row xs:gap-0 gap-4 w-full"
              >
                <input
                  required
                  type="email"
                  placeholder="Enter your email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="border border-lilac text-white bg-custom-white px-3 md:px-4 w-full xl:max-w-[247px] rounded-xl xs:rounded-[12px_0px_0px_12px] outline-none text-base leading-[100%] placeholder:text-spun-pearl 3xl:h-[52px] 1xl:h-12 h-10"
                />
                <button
                  type="submit"
                  className={`text-lg font-medium leading-[100%] text-white px-4 rounded-xl xs:rounded-[0px_12px_12px_0px] xs:max-w-[125px] w-full whitespace-nowrap 3xl:h-[52px] 1xl:h-12 h-10 primary-button relative overflow-hidden group transition-all duration-300 ${
                    buttonState === "initial" ? "bg-candy" : ""
                  }${
                    buttonState === "loading" ? "bg-yellow-500 cursor-wait" : ""
                  }${buttonState === "subscribed" ? "bg-green-500" : ""}
        `}
                  disabled={buttonState !== "initial"}
                >
                  {buttonState === "initial" && "Join Now"}
                  {buttonState === "loading" && <LoadingIcon />}
                  {buttonState === "subscribed" && "Subscribed"}
                </button>
              </form>
            </div> */}

            {/* Mobile logo and links repeated for smaller screens */}
            <div className="xl:max-w-[306px] md:max-w-[48%] w-full md:hidden block xl:hidden">
              <Link
                to={"/"}
                className="w-full md:max-w-[214px] sm:max-w-[160px] max-w-[140px] flex"
              >
                <LogoIcon />
              </Link>
              <p className="text-base sm:text-lg leading-[110%] tracking-[-0.18px] text-white my-5">
                Realize the Vision of Autonomous Enterprise
              </p>
              <SocialLinks links={footerItems.socialLinks} />
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
