import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import PrimaryButton from "./PrimaryButton";
import { MenuIcon } from "./Icons";
import NavLinks from "./NavLinks";
import Hero from "../homepage/Hero";
import { navItems } from "./Helper";
import { LogoIcon } from "./Icons";
import MobileSidebar from "./MobileSidebar";
import ContactUs from "./ContactUs";

const Header = () => {
  const [isSidebarVisible, setSidebarVisible] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  function handleopenContactUsModal() {
    setIsOpen(true);
  }

  function handlecloseContactUsModal() {
    setIsOpen(false);
  }

  const toggleSidebar = () => {
    setSidebarVisible(!isSidebarVisible);
  };

  useEffect(() => {
    document.body.style.overflow = isSidebarVisible ? "hidden" : "auto";
    return () => {
      document.body.style.overflow = "auto";
    };
  }, [isSidebarVisible]);
  return (
    <>
      <header className="relative w-full hero-bg">
        <div className="relative z-40 3xl:h-[96px] md:h-[75px] h-14 flex items-center">
          <div className="flex justify-between items-center gap-4 main-container">
            <Link
              className="md:max-w-[215px] sm:max-w-[180px] max-w-[130px] w-full"
              to={"/"}
            >
              <LogoIcon />
            </Link>
            <div className="flex justify-between items-center gap-14">
              <ul className="hidden md:flex items-center gap-8">
                {navItems.map((item, index) => (
                  <NavLinks item={item} key={index} />
                ))}
              </ul>
            </div>
            <div className="flex items-center gap-2">
              <PrimaryButton
                onClick={handleopenContactUsModal}
                className="max-w-[180px] w-full xs:block hidden"
              >
                Contact Us
              </PrimaryButton>
              <button onClick={toggleSidebar} className="block md:hidden">
                <MenuIcon />
              </button>
            </div>
          </div>
        </div>
        <MobileSidebar
          isSidebarVisible={isSidebarVisible}
          toggleSidebar={toggleSidebar}
        />
        <Hero />
      </header>

      <ContactUs
        isOpen={isOpen}
        handlecloseContactUsModal={handlecloseContactUsModal}
      />
    </>
  );
};

export default Header;
