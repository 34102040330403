import Header from "../common/Header";
import Footer from "../common/Footer";
import AutonomousEnterprise from "../homepage/AutonomousEnterprise";
import WhatMakes from "../homepage/WhatMakes";
import KeyBenefits from "../homepage/KeyBenefits";
import CoreCapabilities from "../homepage/CoreCapabilities";
import AllRightReserved from "../common/AllRightReserved";
import ScrollTop from "../common/ScrollTop";

const Homepage = () => {
  return (
    <>
      <Header />
      <AutonomousEnterprise />
      <WhatMakes />
      <CoreCapabilities />
      <KeyBenefits />
      <Footer />
      <AllRightReserved />
      <ScrollTop />
    </>
  );
};

export default Homepage;
