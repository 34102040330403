const CoreCapabilitiesCard = ({ benefitsItem }) => {
  return (
    <>
      <article className="lg:px-10 px-6 3xl:py-[30px] md:py-5 py-3 flex items-center justify-center flex-col rounded-[28px] cursor-pointer">
        <span className="3xl:max-w-[180px] 2xl:max-w-[120px] 1xl:max-w-[100px] md:max-w-20 max-w-[70px] 3xl:max-h-[180px] 2xl:max-h-[120px] 1xl:max-h-[100px] md:max-h-20 max-h-[70px] w-full h-full rounded-full ">
          {benefitsItem.icon}
        </span>
        <h4 className="2xl:text-[32px] 1xl:text-3xl xl:text-2xl lg:text-xl text-lg font-bold text-black-howl mt-4 lg:mt-6 3xl:mt-[38px] text-center max-w-[339px]">
          {benefitsItem.descriptions}
        </h4>
      </article>
    </>
  );
};

export default CoreCapabilitiesCard;
