import { useState } from "react";
import ContactUs from "../common/ContactUs";
import PrimaryButton from "../common/PrimaryButton";

const DiscoverTheFuture = () => {
  const [isOpen, setIsOpen] = useState(false);

  function handleopenContactUsModal() {
    setIsOpen(true);
  }

  function handlecloseContactUsModal() {
    setIsOpen(false);
  }

  return (
    <>
      <section className="main-container">
        <div className="relative px-4 discover-bg xl:py-[66px] py-6 sm:py-10 xl:mb-[155px] sm:mb-[75px] mb-10">
          <h4 className="max-w-[972px] text-center 1xl:text-[48px] xl:text-[40px] lg:text-4xl md:text-3xl text-2xl font-bold !leading-[120%] text-black-howl tracking-[0.48px] mx-auto">
            Discover the future of the autonomous enterprise with Actionabl.
          </h4>
          <p className="sub-heading text-center leading-[140%] text-deep-night mt-4 xl:mb-12 lg:mb-10 mb-6 max-w-[654px] mx-auto w-full">
            Let's connect to explore how we can help elevate your business
            operations to new heights of efficiency and innovation
          </p>
          <PrimaryButton
            onClick={handleopenContactUsModal}
            className={"max-w-[164px] w-full mx-auto"}
          >
            Contact Us
          </PrimaryButton>
        </div>
      </section>

      <ContactUs
        isOpen={isOpen}
        handlecloseContactUsModal={handlecloseContactUsModal}
      />
    </>
  );
};

export default DiscoverTheFuture;
